import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ArticlesCard from './ArticlesCard';
import '../../styles/article.scss';

const ArticlesBox = ({ articles }) => {
    const reverseOrderArticles = articles.slice().reverse();
    return (
        <Row xs={1} md={3} className="g-4 articles-box">
            {reverseOrderArticles.map(article => (
                <Col key={article.slug}>
                    <ArticlesCard article={article} />
                </Col>
            ))}
        </Row>
    );
};

export default ArticlesBox;
