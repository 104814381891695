import { Link } from 'gatsby';
import React from 'react';
import Card from 'react-bootstrap/Card';
import '../../styles/article.scss';
import { encodeArticleURL } from '../../utils/link-utils';

const ArticlesCard = ({ article }) => {
    const linkTo = `${encodeArticleURL(article.slug)}`;
    return (
        <>
            <Link className="article-link" to={linkTo} activeStyle={{ color: 'blue' }}>
                <Card className="articleCard" border="info" key={article.slug}>
                    <Card.Img className="articleImg" variant="Top" src={article.image} />
                    <Card.Body className="articleBody">
                        <Card.Title className="articleTitle">{article.title}</Card.Title>
                        <Card.Text className="articleText">{article.author}</Card.Text>
                    </Card.Body>
                </Card>
            </Link>
        </>
    );
};

export default ArticlesCard;
