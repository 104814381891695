import React from 'react';
import ArticlesBox from '../components/Article/ArticlesBox';
import ArticlesTitle from '../components/Article/ArticlesTitle';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../styles/article.scss';

const ArticlesList = ({ pageContext: { articles } }) => {
    const url =
        typeof window !== 'undefined' ? window.location.href : `https://www.earthhero.org/articles`;

    return (
        <Layout>
            <SEO title="Earth Hero Published Articles" url={url} pageType="article" />
            <section className="articles">
                <ArticlesTitle>Understanding our Planet to Benefit Humankind</ArticlesTitle>
                <ArticlesBox articles={articles} />
            </section>
        </Layout>
    );
};

export default ArticlesList;
